<template>
    <div class="container">
      <!-- Table -->
      <el-form ref="form" :model="model" :show-message="false" label-width="auto" label-position="top" v-loading="loading">
        <div class="row justify-content-center">
            <div class="col">
                <el-form-item prop="excel" :rules="[{required:true}]">
                <el-upload
                    class="text-center"
                    name="excel"
                    drag
                    action="/backend/api/uploadexcel"
                    :with-credentials="true"
                    :show-file-list="false"
                    :on-success="handleUploadSuccess"
                    :on-error="handelUploadError"
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet">
                    <i class="el-icon-upload"></i>
                    <div class="el-upload__text ls-1">拖拽文件到这里或<em>浏览本地文件</em></div>
                    <div class="el-upload__tip" slot="tip">
                        <span class="ls-1">请上传后缀名为「xlsx」的文件，文件大小不能超过2MB</span><br/>
                        <b><a :href="proxyHost+'/importsamples/sample-songcai.xlsx'" style="line-height:1.3">[ 下载表格模版 ]</a></b>
                    </div>
                </el-upload>
                </el-form-item>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col">
                <div class="el-table" style="max-height: 260px; overflow: scroll; border:1px solid #ececec;">
                <table>
                    <thead>
                        <tr>
                            <td v-for="col in excelData.headers" :key="col" class="pl-2">{{ col }}</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(row,rowIdx) in excelData.rows" :key="rowIdx">
                            <td v-for="(col,colIdx) in row" :key="rowIdx+'-'+colIdx" class="pl-2">{{ col }}</td>
                        </tr>
                    </tbody>
                </table>
                </div>
            </div>
        </div>
        <div class="row justify-content-center mt-5">
            <div class="col-sm-12 col-md-6 col-lg-4">
                <el-form-item prop="month" :rules="[{required:true}]">
                <el-date-picker v-model="model.month" type="month" class="mr-1 fw" placeholder="选择填报月份"
                    format="yyyy-MM" value-format="yyyy-MM">
                </el-date-picker>
                </el-form-item>
            </div>
        </div>
        <div class="row">
            <div class="col text-center">
                <el-button type="success" class="mt-4 ls-3" @click="submit" :disabled="!ready || !model.month" v-loading="loading">导入</el-button>
                <el-button type="secondary" class="mt-4 ls-3" @click="cancel" :disabled="loading">取消</el-button>
            </div>
        </div>
      </el-form>
    </div>
</template>

<script>
/* eslint-disable */
import utils from '@/utils'
export default {
    props: {
        group: {
            type: String,
            description: "Songcai group",
            default: null
        },
        saved: {
            type: Boolean,
            description: "indicating data saved",
            default: false
        },
        cancelled: {
            type: Boolean,
            description: "indicating wether editing is cancelled",
            default: false
        },
        month: {
            type: String,
            description: "Imported to month",
            default: null
        }
    },
    data() {
      return {
        proxyHost: process.env.VUE_APP_BACKEND_TARGET || 'https://guoqi.ycycsw.cn',
        tmp_saved: this.saved,
        tmp_cancelled: this.cancelled,
        model: {
            group: this.group,
            month: '',
            excel: ''
        },
        excelData: { headers: [], rows: []},
        ready: false,
        loading: false
      }
    },
    watch: {
        group(v) {
            this.model.group = v;
        },
        tmp_saved(v) {
            // telling parents component the data is saved
            this.$emit("update:saved", v);
        },
        tmp_cancelled(v) {
            // telling parents component user cancelled editing
            this.$emit("update:cancelled", v);
        }
    },
    methods: {
        handleUploadSuccess(res, file) {
            if(res.errcode != 0) {
                this.$notifyErr('文件上传失败', res.errmsg)
            }
            else {
                this.model.excel = res.url;
                this.excelData = res.data;
                this.ready = true;
            }
        },
        handelUploadError(err) {
            alert('上传失败')
        },
        submit() {
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    if(!this.model.excel) { alert('请上传表格'); return false; }
                    this.save();
                } else {
                    alert('请填写完整信息');
                    return false;
                }
            });
        },
        save() {
            this.loading = true;
            this.axios({
                method: 'post',
                url: 'songcais/import',
                data: this.model
            }).then((res) => {
                this.loading = false;
                if (res.data.errcode == 0) {
                    this.tmp_saved = true;
                    this.$emit("update:month", this.model.month);
                }
                else {
                    this.$notifyErr('导入失败', res.data.errmsg);
                }
            }).catch((err) => {
                if(err.response) {
                    if(err.response.data.error) this.$notifyErr('导入失败', err.response.data.error.message);
                    else this.$notifyErr('导入失败', err.response.data[0].message);
                }
                else this.$notifyErr('导入失败', 'Failed: ' + err.message);
                this.loading = false;
            })
        },
        cancel() {
            this.tmp_cancelled = true
        }
    },
    mounted() {
        this.tmp_cancelled = false;
        this.tmp_saved = false;
    }
};
</script>
<style>
    .el-select, .el-autocomplete {
        width: 100%;
    }
    label.required::before {
        content: '*';
        color: red;
        position: absolute;
        top: 0;
        left: 6px;
        font-weight: 300;
        font-size: smaller;
    }
    .el-upload, .el-upload-dragger {
        width: 100%;
        height: 150px;
    }
    .el-upload-dragger .el-icon-upload {
        margin: 25px 0 16px;
    }
    .card .table td, .card .table th, .card .el-table td, .card .el-table th {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }
    div.el-table tbody td, div.el-table thead th {
        padding: 0.3rem;
    }
</style>